import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

const GenericPage = () => {
  const pageTitle = "Freshly Commerce"

  return (
    <Layout pageTitle={pageTitle}>
      <Seo title={pageTitle} />

      <Hero
        heroText="Terms of Service"
        heroDesc={
          <>
            Please download our terms of service{" "}
            <a href="/terms.pdf" download>
              here
            </a>
            . Last updated March 15th, 2024.
          </>
        }
      />
    </Layout>
  )
}
export default GenericPage
